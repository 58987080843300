<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap bgw appay">
					<div class="checking">
						<el-steps :active="state" align-center>
							<el-step title="选择入驻" description></el-step>
							<el-step title="填写资料" description></el-step>
							<el-step title="等待审核" description></el-step>
						</el-steps>
					</div>
					<div v-if="state == 1" class="stepOne">
						<div @click="selectTab(1)" class="fl">
							<img src="@/assets/image/Settledin_Doctor_icon@2x.png" alt />
							<p>医生入驻</p>
							<p>入驻成为问诊医生、进行线上问诊流程</p>
						</div>
						<div @click="selectTab(2)" class="fr">
							<img src="@/assets/image/daili.png" alt />
							<p>服务商入驻</p>
							<p>低价批发商品，推广商品得佣金</p>
						</div>
					</div>
					<div v-if="state==2">
						<div v-if="cert_type == 1" class="stepTwo">
							<div class="box">
								<div class="fl">姓名</div>
								<div class="fl">
									<input v-model="realname" type="text" maxlength="12" />
								</div>
							</div>
							<div class="box">
								<div class="fl">手机号</div>
								<div class="fl">
									<input v-model="mobile" type="text" maxlength="11" />
								</div>
							</div>
							<!-- 资质图片 -->
							<div class="checkingImg">
								<div class="fl">资质证明</div>
								<div class="fl imgList">
									<el-upload :action="imageUrl" list-type="picture-card" :on-success="ListImgSuccess" :before-upload="beforeAvatarUpload"
									 :on-remove="ListImgRemove">
										<i class="el-icon-plus"></i>
									</el-upload>
								</div>
							</div>
							<div class="box">
								<div class="fl">资质简介</div>
								<div class="fl">
									<input v-model="zizhi_desc" type="text" />
								</div>
							</div>
							<div class="box">
								<div class="fl">个人简介</div>
								<div class="fl">
									<input v-model="jianjie" type="text" />
								</div>
							</div>
							<div class="box">
								<div class="fl">身份证号码</div>
								<div class="fl">
									<input v-model="idcard_no" type="text" maxlength="18" />
								</div>
							</div>
							<div class="box">
								<div class="fl w">医生职业医疗机构代码</div>
								<div class="fl">
									<input v-model="jigou_code" type="text" />
								</div>
							</div>
							<div class="box">
								<div class="fl w">医生职业医疗机构名称</div>
								<div class="fl">
									<input v-model="jigou_name" type="text" />
								</div>
							</div>
							<div class="checkingImg userImg">
								<div class="fl">头像</div>
								<div class="fl">
									<el-upload :action="imageUrl" list-type="picture-card" :on-success="UserImgSuccess" :before-upload="beforeAvatarUpload"
									 :on-remove="UserImgtRemove">
										<i class="el-icon-plus"></i>
									</el-upload>
								</div>
							</div>
							<div class="box select">
								<div class="fl">问诊科目</div>
								<div class="fl">
									<el-cascader @change="changeCate" :options="arr1" clearable>
										
									</el-cascader>
								</div>
							</div>
							<div class="box">
								<div class="fl"></div>
								<div class="fl">
									<button @click="checking()">申请</button>
								</div>
							</div>
						</div>
						<div v-if="cert_type == 2" class="stepTwo">
							<div class="box">
								<div class="fl">身份证号:</div>
								<div class="fl">
									<input v-model="agent_card" type="number" />
								</div>
							</div>
							<div class="box">
								<div class="fl">姓名:</div>
								<div class="fl">
									<input v-model="agent_name" type="text" />
								</div>
							</div>
							<div class="checkingImg">
								<div class="fl">身份证正反面:</div>
								<div class="card_box">
									<div class="fl imgList">
										<el-upload :action="imageUrl" list-type="picture-card" :on-success="positive" :before-upload="beforeAvatarUpload"
										 :on-remove="ListImgRemove">
											<p>正面</p>
										</el-upload>
									</div>
									<div class="fl imgList">
										<el-upload :action="imageUrl" list-type="picture-card" :on-success="negative" :before-upload="beforeAvatarUpload"
										 :on-remove="ListImgRemove">
											<p>反面</p>
										</el-upload>
									</div>
								</div>

							</div>
							<div class="checkingImg">
								<div class="fl">单位资质证明:</div>
								<div class="card_box">
									<div class="fl imgList">
										<el-upload :action="imageUrl" list-type="picture-card" :on-success="seniority" :before-upload="beforeAvatarUpload"
										 :on-remove="ListImgRemove">
											<p class="el-icon-plus"></p>
										</el-upload>
									</div>
								</div>
							</div>
							<div class="box">
								<div class="fl"></div>
								<div class="fl">
									<button @click="agentapply">申请</button>
								</div>
							</div>
						</div>
					</div>
					<!-- 审核结果 -->
					<div v-if="state == 3" class="stepThree">
						<div v-if="type == 0" class="box box0">
							<img src="@/assets/image/Settledin_waitfor_img@2x.png" alt />
							<p>已提交申请，等待审核</p>
						</div>
						<div v-if="type == 2" class="box">
							<img src="@/assets/image/Settledin_fail_img@2x.png" alt />
							<p>认证申请失败</p>
							<p @click="again">重新提交申请</p>
						</div>
						<div v-if="type == 1&&cert_type==1" class="box">
							<img src="@/assets/image/ShoppingCart_Success@2x.png" alt />
							<p>医生入驻审核通过</p>
							<!-- <p>审核通过后请<span @click="$router.push({name:'保证金'})">缴纳保证金</span>后,成为分销商</p> -->
						</div>
						<div v-if="type == 1&&cert_type==2" class="box">
							<img src="@/assets/image/ShoppingCart_Success@2x.png" alt />
							<p>服务商入驻审核通过</p>
							<p>审核通过后请<span @click="$router.push({name:'保证金'})">缴纳保证金</span>后,成为分销商</p>
						</div>
						<div v-if="type == 3" class="box">
							<img src="@/assets/image/ShoppingCart_Success@2x.png" alt />
							<p>服务商入驻审核通过</p>
							<p>您已经成为代理商，您可以随时<span @click="returnmoney">退回保证金</span></p>
						</div>
						<div v-if="type == 4" class="box">
							<img src="@/assets/image/ShoppingCart_Success@2x.png" alt />
							<p>退款申请已通过</p>
							<p>退回保证金申请中请耐心等待</p>
						</div>
						<div v-if="type == 5" class="box">
							<img src="@/assets/image/ShoppingCart_Success@2x.png" alt />
							<p>退款申请已通过</p>
							<p>保证金已退回，可再次申请<span @click="cert_type = 2;state=2">成为代理商</span></p>
						</div>
					</div>
				</div>
			</div>
			<el-button type="text" @click="open"></el-button>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		getApplyInfo
	} from "@/request/user"; // 查询 申请医生的 状态值
	import {
		keshi_list
	} from "@/request/user"; // 申请 医生列表
	import {
		apply_yisheng
	} from "@/request/user"; // 申请 医生
	import {
		usergetinfo
	} from "@/request/user";

	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				userList: [], // 个人信息
				state: 1, // 步骤
				type: 0, // 等待 还是 成功 还是 失败
				UserImg: [],
				playType: 0, //1 是医生  2是 专家
				arr1: [], // 科室选择
				realname: "", // 姓名
				mobile: "", // 手机号
				zizhi_desc: "", //资质 简介
				zizhi_pic: [], //资质 图片
				jianjie: "", //简介
				idcard_no: "", // 身份证号
				jigou_code: "", // 诊疗医师执业医疗机构代码
				jigou_name: "", // 机构名称
				avatar: "", //      头像
				category_id: "", //问诊类别
				cert_type: 0, //  角色 1 医生 2 
				agent_card: '', //代理商身份证号
				agent_name: '', //代理商名字
				agent_positive: "", //身份证正面
				agent_negative: "", //身份证正面
				agent_seniority: "", //代理商资质

			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}

			this.init();
			this.start();
			// this.state=this.$route.query.state
		},
		methods: {
			returnmoney() {
				this.$axios.post("/api/serviceprovider/backbaozhenjin", {
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						this.$message.success("退款申请提交成功，请耐心等待审核");
					}
				})
			},
			again() {
				this.state = 2;
			},
			// 弹框
			open() {
				this.$alert('代理商入驻申请已提交,工作人员会尽快审核,请耐心等待', '提交成功', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.go(-1);
					}
				});
			},
			//正面
			positive(response, file, fileList) {
				this.agent_positive = response.data.file.url;
			},
			//反面
			negative(response, file, fileList) {
				this.agent_negative = response.data.file.url;
			},
			//资质
			seniority(response, file, fileList) {
				this.agent_seniority = response.data.file.url;
			},
			// 代理商申请
			agentapply() {
				if (this.agent_card == "") {
					this.$message.error("请输入身份证号");
					return;
				}
				if (this.agent_name == "") {
					this.$message.error("请输入真实姓名");
					return;
				}

				if (this.agent_positive == "") {
					this.$message.error("请上传身份证正面照片");
					return;
				}
				if (this.agent_negative == "") {
					this.$message.error("请上传身份证反面照片");
					return;
				}
				if (this.agent_seniority == "") {
					this.$message.error("请上传资质照片");
					return;
				}
				this.$axios.post("/api/serviceprovider/apply", {
					token: this.$token,
					realname: this.agent_name, //姓名
					cret_pic: this.agent_seniority, //资质
					idcard_no: this.agent_card, //身份证号
					idcard_pic1: this.agent_positive, //身份证正面
					idcard_pic2: this.agent_negative //  身份证反面
				}).then(res => {
					if (res.data.code == 1) {
						this.open();
					}
				})
			},
			checking() {
				if (this.realname == "") {
					this.$message.error("名字不能为空");
					return;
				} else if (this.mobile == "") {
					this.$message.error("手机号不能为空");
					return;
				} else if (this.zizhi_desc == "") {
					this.$message.error("资质简介不能为空");
					return;
				} else if (this.zizhi_pic == "") {
					this.$message.error("资质图片不能为空");
					return;
				} else if (this.jianjie == "") {
					this.$message.error("个人简介不能为空");
					return;
				} else if (this.idcard_no == "") {
					this.$message.error("身份证号不能为空");
					return;
				} else if (this.jigou_code == "") {
					this.$message.error("诊疗医师执业医疗机构代码");
					return;
				} else if (this.jigou_name == "") {
					this.$message.error("机构名称");
					return;
				} else if (this.avatar == "") {
					this.$message.error("头像不能为空");
					return;
				} else if (this.category_id == "") {
					this.$message.error("问诊类别不能为空");
					return;
				} else {
					this.send();
				}
			},
			send() {
				let imgData = this.zizhi_pic;
				let arrimg = [];
				for (let i = 0; i < imgData.length; i++) {
					arrimg.push(imgData[i].url);
				}
				arrimg = arrimg.join(",");

				apply_yisheng({
					token: this.$tokens,
					realname: this.realname, //姓名
					mobile: this.mobile, // 手机号
					zizhi_desc: this.zizhi_desc, //资质简介
					zizhi_pic: arrimg, //资质 图片
					jianjie: this.jianjie, //个人简介
					idcard_no: this.idcard_no, // 身份证号
					jigou_code: this.jigou_code, //诊疗医师执业医疗机构代码
					jigou_name: this.jigou_name, //机构名称
					avatar: this.avatar, //头像
					category_id: this.category_id, //问诊 类别
					cert_type: this.cert_type //问诊类别
				}).then(res => {
					if (res.code == 1) {
						this.state = 3;
						this.$message({
							message: "发送成功",
							type: "success"
						});
						this.type = 0;
					}else{
						this.$message({
							message: res.msg,
							type: "error"
						});
					}
				});
			},
			// 选择  医生 还是 服务商
			selectTab(e) {
				this.cert_type = e;
				if (e == 1) {
					this.$axios.post("/api/serviceprovider/getApplyInfo", {
						token: this.$token,
						is_pc:1,
					}).then(res => {
						if(res.data.data==''||res.data.data.status==2||res.data.data.status==5){
							this.$axios.post("/api/doctor/getApplyInfo", {
								token: this.$token
							}).then(res => {
								if (res.data.code == 1) {
									this.state = 3;
									this.type = res.data.data.status;
								} else {
									this.state = 2;
									this.cert_type = e;
								}
							})
						}else{
							this.$message.error("您已经申请了服务商资格，不可再申请成为医生");
						}
						
					})

				} else {
					this.$axios.post("/api/doctor/getApplyInfo", {
						token: this.$token
					}).then(res => {
						if(res.data.data==""||res.data.data.status==2){
							this.$axios.post("/api/serviceprovider/getApplyInfo", {
								token: this.$token
							}).then(res => {
								console.log(res);
								if (res.data.code == 1) {
									this.state = 3;
									this.type = res.data.data.status;
								} else {
									this.state = 2;
									this.cert_type = e;
								}
							})
						}else{
							this.$message.error("您已经申请了医生资格，不可再申请成为服务商");
						}
					})

				}


			},
			start() {
				// //  查询 申请医生 的 状态
				// getApplyInfo({
				// 	token: this.$tokens
				// }).then(res => {
				// 	console.log(res);
				// 	if (res.code == 0) {
				// 		this.state = 1;
				// 	}else{
				// 		this.state =3;
				// 		this.type=res.data.status
				// 	}

				// });
				//   选择 问诊 科室
				keshi_list({}).then(res => {
					let data = res.data;
					console.log(data);
					for (let i = 0; i < data[0].length; i++) {
						this.arr1.push({
							label: data[0][i][1],
							value: data[0][i][0],
							children: []
						});
					}
					for (let prop in data) {
						for (let i = 0; i < this.arr1.length; i++) {
							if (this.arr1[i].value == prop) {
								for (let j = 0; j < data[prop].length; j++) {
									this.arr1[i].children.push({
										label: data[prop][j][1],
										value: data[prop][j][0]
									});
								}
							}
						}
					}
				});
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			//   资质证明 图的 list
			ListImgSuccess(response, file, fileList) {
				let imgurl = response.data.file.url;
				file.url = imgurl;
				this.zizhi_pic = fileList;
			},
			//   资质证明 删除
			ListImgRemove(file, fileList) {
				this.zizhi_pic = fileList;
			},
			//  个人 头像
			UserImgSuccess(response, file) {
				let imgurl = response.data.file.url;
				file.url = imgurl;
				this.avatar = response.data.file.url;
			},
			// 个人头像 删除
			UserImgtRemove() {
				this.avatar = "";
			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
			},
			changeCate(res) {
				if (res[1]) {
					this.category_id = res[1];
				} else {
					this.category_id = res[0];
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.card_box {
		display: flex;

		.imgList {
			width: 148px !important;
			height: 150px;
			margin-right: 20px;
			text-align: center;
			font-size: 18px;
			overflow: hidden;

			/deep/.el-upload {
				margin-right: 20px;
				color: #CCCCCC
			}
		}
	}

	.stepThree {
		.box {
			height: 400px;
			text-align: center;
		}

		img {
			width: 108px;
			height: 108px;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 20px;
			font-size: 20px;

			span {
				color: #4EEEC8;
			}

		}

		p:nth-of-type(1) {
			color: #313131;
			font-size: 26px;
			margin-bottom: 24px;
		}

		p:nth-of-type(2) {
			cursor: pointer;
		}

		.box0 {
			img {
				height: 20px;
				margin-bottom: 60px;
			}
		}
	}

	.checkingImg {
		margin-bottom: 36px;
		overflow: hidden;

		.fl:nth-of-type(1) {
			width: 150px;
			font-size: 18px;
		}

		.fl:nth-of-type(2) {
			width: 800px;
		}
	}

	// 步骤2
	.stepTwo {
		padding-left: 150px;
		padding-bottom: 60px;

		.box {
			margin-bottom: 36px;
			height: 46px;
			line-height: 46px;

			.fl:nth-of-type(1) {
				width: 150px;
				height: 46px;
				font-size: 18px;
			}

			.fl:nth-of-type(2) {
				width: 800px;
			}
		}

		.w {
			width: 190px !important;
		}

		.userImg {
			.fl:nth-of-type(2) {
				width: 156px;
				height: 156px;
				overflow: hidden;
			}
		}

		input {
			border: 1px solid #a8a8a8 !important;
			width: 400px;
			height: 46px;
			box-sizing: border-box;
			font-size: 16px;
			text-indent: 10px;
		}

		.el-select {
			//   background: red;
			box-sizing: border-box;
			padding-left: 2px;
			border: 1px solid #a8a8a8 !important;
		}

		button {
			width: 140px;
			height: 52px;
			border-radius: 4px;
		}
	}

	//步骤条
	.checking {
		padding-top: 64px;
		padding-bottom: 120px;
	}

	// 步骤1
	.stepOne {
		padding-left: 86px;
		padding-right: 86px;
		height: 502px;

		>div {
			width: 502px;
			height: 280px;
			border-radius: 16px;
			font-size: 20px;
			color: white;
			text-align: center;
			padding-top: 50px;

			p:nth-of-type(1) {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 20px;
			}
		}

		img {
			width: 57px;
			height: 63px;
			margin-bottom: 30px;
		}

		.fl {
			background: linear-gradient(-56deg,
				rgba(69, 239, 197, 1) 0%,
				rgba(114, 228, 200, 1) 100%);
			cursor: pointer;
		}

		.fr {
			background: linear-gradient(-49deg,
				rgba(80, 175, 229, 1) 0%,
				rgba(125, 190, 227, 1) 100%);
			cursor: pointer;
		}
	}
</style>
